import cn from 'classnames';
import React from 'react';
import { bool } from 'prop-types';

import './preloader_screen/_preloader_screen.sass';

const PreloaderScreen = ({ relative }) => {
  const preloaderCN = cn('preloader_screen', { 'preloader_screen-relative': relative });

  return (
    <div className={preloaderCN}>
      <span className="preloader_screen__icon " />
    </div>
  );
};

PreloaderScreen.propTypes = {
  relative: bool,
};

PreloaderScreen.defaultProps = {
  relative: false,
};

export default PreloaderScreen;
