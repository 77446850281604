import React from 'react';
import { Tween } from 'react-gsap';
import { useToggle } from 'react-hooks-lib';

import RequestForm from 'components/form/request_form';
import Times from 'components/ui/Times';

import RequestButton from './request_button';

import './request.sass';

const Request = () => {
  const { on, toggle } = useToggle(false);
  return (
    <div className="request">
      {on && (
        <Tween
          from={{ rotationY: 90, transformOrigin: 'center' }}
          ease="Back.easeOut"
          transition={0.8}
        >
          <div className="request__body">
            <Times on={on} onClick={toggle} color="#FFF" />
            <RequestForm />
          </div>
        </Tween>
      )}

      {!on && <RequestButton onClick={toggle} />}
    </div>
  );
};

export default Request;
