import React from 'react';
import typograf from 'utils/typograf';
import { node } from 'prop-types';

const HTML = ({ children }) => (
  <div
    className="html"
    dangerouslySetInnerHTML={{ __html: typograf(children) }}
  />
);

HTML.propTypes = {
  children: node.isRequired,
};

export default HTML;
