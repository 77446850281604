import BodyClassName from 'react-body-classname';
import cn from 'classnames';
import PreloaderScreen from 'components/ui/preloader_screen';
import React from 'react';
import ScrollLock from 'react-scrolllock';
import { Location } from '@reach/router';
import { node, string } from 'prop-types';
import { Preloader, Placeholder } from 'react-preloading-screen';
import { slide as Menu } from 'react-burger-menu'; // https://github.com/negomi/react-burger-menu
import { useMergeState } from 'react-hooks-lib';

import Footer from './layout/footer';
import Header from './layout/header';
import Nav from './layout/nav';
import OffersNav from './blocks/offers_nav';
import Request from './ui/Request';
import Services from './blocks/services';

import '../sass/screen.sass';

const styles = {
  bmCrossButton: {
    height: '34px',
    width: '34px',
  },
};

const Layout = ({ children, pageType }) => {
  // states
  const { state, set } = useMergeState({
    isOpen: false,
    isLock: false,
  });
  const { isOpen } = state;

  // classNames
  const sidebarCN = cn('sidebar', { 'sidebar-active': isOpen });
  const BodyCN = cn('body', { [`body-${pageType}`]: pageType });

  // handles
  const handleSidebarToggle = () => set(() => ({ isOpen: !isOpen }));
  const handleSidebarOnChange = (e) => set(() => ({ isOpen: e.isOpen }));

  return (
    <Location>
      {({ location }) => {
        const { pathname } = location;
        const parentPath = pathname.split('/')[1];

        return (
          <>
            {/* Sidebar */}
            <Menu
              className={sidebarCN}
              customBurgerIcon={false}
                // customCrossIcon={<button type="button" className="times">⨂</button>}
              disableAutoFocus
              isOpen={isOpen}
              onStateChange={(e) => handleSidebarOnChange(e)}
                // outerContainerId="outer-container"
              pageWrapId="page-wrap"
              push
              right
              styles={styles}
              width="auto"
            >
              <Nav isOpen={isOpen} onClick={handleSidebarToggle} />
            </Menu>

            {/* Page */}
            <Preloader>
              <div id="page-wrap" className="page">

                <Header isOpen={isOpen} onClick={handleSidebarToggle} />

                {/* Main content */}
                <main className="main">{children}</main>

                {parentPath !== 'offers' && <OffersNav />}
                {parentPath !== 'services' && <Services />}
                <Footer />
              </div>
              <Placeholder><PreloaderScreen /></Placeholder>
            </Preloader>

            <BodyClassName className={BodyCN} />
            <ScrollLock isActive={isOpen} />
            <Request />
          </>
        );
      }}
    </Location>
  );
};

Layout.propTypes = {
  children: node.isRequired,
  pageType: string,
};

Layout.defaultProps = {
  pageType: null,
};

export default Layout;
