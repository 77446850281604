import React from 'react';
import { func } from 'prop-types';

import raw from 'utils/raw';

// SASS
import './request_button.sass';

const ComponentName = ({ onClick }) => (
  <button
    className="link link-centered link-undecorated request_button"
    onClick={onClick}
    type="button"
  >
    <span className="request_button__inner">{raw('Оставить<br/>заявку')}</span>
  </button>
);

ComponentName.propTypes = {
  onClick: func.isRequired,
};

export default ComponentName;
