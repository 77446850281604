import cn from 'classnames';
import React from 'react';
import { node, bool } from 'prop-types';

const Container = ({ children, unpadding }) => {
  const containerCN = cn('container', { 'container-unpadding': unpadding });

  return <div className={containerCN}>{children}</div>;
};

Container.propTypes = {
  children: node.isRequired,
  unpadding: bool,
};

Container.defaultProps = {
  unpadding: false,
};


export default Container;
