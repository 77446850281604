import raw from 'utils/raw';
import React from 'react';
import { Link } from 'gatsby';

const text = 'Большую часть услуг мы оказываем удаленно, используя удобные современные инструменты. Наши клиенты получают экспертизу всей нашей команды и возможность получения комплексного сопровождения бизнеса.';

const FaqLink = () => (
  <div className="faq_link">
    <p className="faq_link__main faq_link__main-masked">{raw(text)}</p>
    <Link
      to="/faq/"
      className="link link-nowrap link-line link-color faq_link__link"
    >
      Частые вопросы
    </Link>
  </div>
);

export default FaqLink;
