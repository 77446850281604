import cn from 'classnames';
import { Link } from 'gatsby';
import raw from 'utils/raw';
import React from 'react';
import { string, bool } from 'prop-types';

const ServicesLink = ({
  icon, link, caption, name, small,
}) => (
  <Link
    activeClassName="services_link-active"
    className={cn('services_link', { [`services_link-${icon}`]: icon }, { 'services_link-small': small })}
    title={name.replace('<br/>', ' ')}
    to={link}
  >
    <span className={cn('services_link__text', `services_link__text-${icon}`)}>{raw(name)}</span>
    {caption && <span className="services_link__caption" style={{ textDecoration: 'none' }}>{raw(caption)}</span>}
  </Link>
);

ServicesLink.propTypes = {
  caption: string,
  icon: string,
  link: string.isRequired,
  name: string.isRequired,
  small: bool,
};

ServicesLink.defaultProps = {
  caption: null,
  icon: null,
  small: false,
};

export default ServicesLink;
