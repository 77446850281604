import cn from 'classnames';
import { Link } from 'gatsby';
import raw from 'utils/raw';
import React from 'react';
import typograf from 'utils/typograf';

import Container from 'components/layout/container';

// Data
import data from 'data/offers.yaml';

const { title, nav } = data;

const OffersNav = () => (
  <Container>
    <nav className="offers_nav">
      <h4 className="offers_nav__title">{raw(typograf(title))}</h4>

      <ul className="offers_nav__body">
        {nav.map(({ name, path, icon }) => {
          const linkCN = cn('offers_nav__link', { [`offers_nav__link-${icon}`]: icon });

          return (
            <li className="offers_nav__item" key={path}>
              <Link
                className={linkCN}
                to={path}
              >
                <span className="offers_nav__label">{raw(name)}</span>
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  </Container>
);

export default OffersNav;
