import raw from 'utils/raw';
import React from 'react';
import typograf from 'utils/typograf';
import { string, bool } from 'prop-types';
import { Tween, SplitWords } from 'react-gsap';

const PageTile = ({
  label, title, hideLabel, subtitle,
}) => (
  <h1 className="page_title">
    {label && !hideLabel && (
      <>
        <Tween
          staggerFrom={{ x: '-100', opacity: 0, color: '#EBFF8B' }}
          delay={0.5}
          duration={0.2}
          ease="Back.easeOut"
          stagger={0.08}
        >
          <SplitWords>
            <span className="page_title__label">{label}</span>
          </SplitWords>
        </Tween>
        <br />
      </>
    )}

    <Tween
      staggerFrom={{ x: '-100', opacity: 0, color: '#EBFF8B' }}
      delay={0.6}
      duration={0.2}
      ease="Back.easeOut"
      stagger={0.08}
    >
      <SplitWords>
        <span className="page_title__title">{typograf(title)}</span>
      </SplitWords>
    </Tween>

    {subtitle && (
      <Tween
        from={{ opacity: 0 }}
        delay={1}
        duration={0.6}
      >
        <span className="page_title__subtitle">{raw(typograf(subtitle))}</span>
      </Tween>
    )}
  </h1>
);

PageTile.propTypes = {
  hideLabel: bool,
  label: string,
  subtitle: string,
  title: string.isRequired,
};

PageTile.defaultProps = {
  hideLabel: false,
  label: 'Бухгалтерия нового поколения',
  subtitle: null,
};

export default PageTile;
