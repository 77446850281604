import cn from 'classnames';
import React, { Fragment } from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import { Location } from '@reach/router';
import { Power4 } from 'gsap/EasePack';
import { Tween } from 'react-gsap';
import { bool } from 'prop-types';

// Components
import ServiceLink from 'blocks/services_link';

// Data
import services from 'data/services.yaml';

const servicesNav = ({ row }) => {
  const servicesGridCN = cn('services_nav__inner', { 'services_nav__inner-row': row });

  return (
    <nav className="services_nav">
      <Location>
        {({ location }) => {
          const { pathname } = location;

          return (
            <Controller>
              <Scene
                duration={350}
                offset={-200}
              >
                <Tween
                  wrapper={<ul className={servicesGridCN} />}
                  staggerFrom={{
                    opacity: 0,
                    cycle: {
                      y: (i) => (i + 1) * 40,
                    },
                    ease: Power4.easeOut,
                  }}
                  stagger={0.1}
                >
                  {services.map(({
                    name, icon, link, caption,
                  }) => {
                    const isHidden = pathname === link;
                    if (isHidden) return <Fragment key={link} />;

                    return (
                      <li className="services_nav__item" key={link}>
                        <ServiceLink
                          caption={caption}
                          icon={icon}
                          link={link}
                          name={name}
                          small={row}
                        />
                      </li>
                    );
                  })}
                </Tween>
              </Scene>
            </Controller>
          );
        }}
      </Location>
    </nav>
  );
};

servicesNav.propTypes = {
  row: bool,
};

servicesNav.defaultProps = {
  row: false,
};

export default servicesNav;
