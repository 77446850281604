import React from 'react';
import { Tween } from 'react-gsap';
import { useStaticQuery, graphql } from 'gatsby';


const HeaderContacts = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          contacts {
            adress
            tel
          }
        }
      }
    }
  `);
  const { adress, tel } = data.site.siteMetadata.contacts;

  return (
    <div className="header_contacts">
      <Tween from={{ x: '-5px', opacity: 0 }} delay="0.6">
        <a href={`tel:${tel}`} className="link link-undecorated link-uncolored header_contacts__tel">{tel}</a>
      </Tween>
      <Tween from={{ x: '-5px', opacity: 0 }} delay="0.7">
        <span className="header_contacts__adress">{adress}</span>
      </Tween>
    </div>
  );
};

export default HeaderContacts;
