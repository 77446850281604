import React from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, SplitLetters } from 'react-gsap';

import Container from 'components/layout/container';
import FaqLink from 'ui/faq_link';
import ParallaxElement from 'components/HOC/parallax_element';
import ReviewsLink from 'ui/reviews_link';

import ServicesNav from './services_nav';

const Services = () => (
  <div className="services">
    {/* Top */}
    <Container>
      <span className="services__top">
        <ParallaxElement scale>
          <ReviewsLink />
        </ParallaxElement>
      </span>
    </Container>

    {/* Masket Inner */}
    <div className="services__inner services__inner-masked">
      <Container>
        <h3 className="services__title title">
          Наши услуги
        </h3>

        <ServicesNav />
      </Container>
    </div>

    {/* Footer */}
    <Container>
      <div className="services__faq">
        <FaqLink />
      </div>
    </Container>
  </div>
);

export default Services;
