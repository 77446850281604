import AniLink from 'gatsby-plugin-transition-link/AniLink';
import React from 'react';
import {
  arrayOf, shape, string, bool,
} from 'prop-types';
import { Tween } from 'react-gsap';
import cn from 'classnames';

import settings from 'utils/settings';

const NavLinks = ({ links, isOpen, footer }) => (
  <ul className="nav_links">
    <Tween
      playState={isOpen && 'play'}
      delay={0.2}
      duration={0.3}
      stagger={0.1}
      staggerFrom={{ x: '10px', opacity: 0 }}
      yoyo
    >
      {links.map(({ name, link }) => {
        const linkCN = cn(
          'nav_links__link',
          { 'nav_links__link-light': footer },
          { 'nav_links__link-eds': link === '/services/eds/' },
        );

        return (
          <li className="nav_links__item" key={name}>
            <AniLink
              activeClassName="nav_links__link-active"
              bg={settings.brandGradient}
              className={linkCN}
              title={name}
              to={link}
              cover
            >
              {name}
            </AniLink>
          </li>
        );
      })}
    </Tween>
  </ul>
);

NavLinks.propTypes = {
  footer: bool,
  isOpen: bool,
  links: arrayOf(
    shape({
      name: string.isRequired,
      link: string.isRequired,
    }).isRequired,
  ).isRequired,
};

NavLinks.defaultProps = {
  footer: false,
  isOpen: false,
};

export default NavLinks;
