import raw from 'utils/raw';
import React from 'react';
import { bool, string, func } from 'prop-types';

// SASS
import './times.sass';

const Times = ({ on, color, onClick }) => {
  if (!on) return <></>;

  return (
    <button type="button" style={{ color }} className="times" onClick={onClick}>{raw('&times;')}</button>
  );
};

Times.propTypes = {
  color: string,
  on: bool,
  onClick: func.isRequired,
};

Times.defaultProps = {
  color: '#000',
  on: true,
};

export default Times;
