const screen = {
  xs: '(min-width: 320px)',
  sm: '(min-width: 544px)',
  md: '(min-width: 768px)',
  lg: '(min-width: 992px)',
  xl: '(min-width: 1170px)',
  xxl: '(min-width: 1440px)',

  max: {
    xs: '(min-width: 319px)',
    sm: '(min-width: 543px)',
    md: '(min-width: 767px)',
    lg: '(min-width: 991px)',
    xl: '(min-width: 1169px)',
    xxl: '(min-width: 1439px)',
  },
};

export default screen;
