import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Container from './container';
import Copyright from './footer/copyright';
import FooterContacts from './footer/footer_contacts';
import YandexMap from './footer/yandex_map';
import Nav from './nav';

const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          copyright
          client
          contacts {
            adress
            tel
            note
            email
          }
        }
      }
    }
  `);

  const { contacts, copyright, client } = data.site.siteMetadata;

  return (
    <footer className="footer">
      <Container>
        <div className="footer__top">
          <FooterContacts {...contacts} />
        </div>
      </Container>

      <Container unpadding>
        <div className="footer__map">
          <YandexMap />
        </div>

      </Container>
      <Container>
        <nav className="footer__nav">
          <Nav footer />
        </nav>
      </Container>

      <Container>
        <div className="footer__bottom">
          <Copyright copyright={copyright} client={client} />
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
