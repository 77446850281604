import HamburgerMenu from 'react-hamburger-menu';
import React from 'react';
import { bool, func } from 'prop-types';

const Hamburger = ({ isOpen, onClick }) => (
  <span className="hamburger">
    <HamburgerMenu
      animationDuration={0.5}
      borderRadius={4}
      color="#CAF2F3"
      height={isOpen ? 20 : 26}
      isOpen={false}
      menuClicked={onClick}
      rotate={0}
      strokeWidth={isOpen ? 2 : 6}
      width={44}
    />
  </span>
);

Hamburger.propTypes = {
  isOpen: bool.isRequired,
  onClick: func.isRequired,
};

export default Hamburger;
