import Media from 'react-media';
import React from 'react';
import screen from 'utils/screen';
import { Controller, Scene } from 'react-scrollmagic';
import { useStaticQuery, graphql } from 'gatsby';
import { YMaps, Map, Placemark } from 'react-yandex-maps';

const coordinates = [56.873023, 35.901152];

const YandexMap = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          contacts {
            mapPinCaption
          }
        }
      }
    }
  `);

  const { mapPinCaption } = data.site.siteMetadata.contacts;

  return (
    <Controller>
      <Scene
        reverse={false}
        triggerHook="onEnter"
      >
        {(progress) => {
          if (progress === 0) return <div className="yandex_map" />;

          return (
            <div className="yandex_map">
              <Media queries={{
                md: screen.md,
              }}
              >
                {(matches) => (
                  <YMaps query={{ lang: 'ru_RU' }}>
                    <Map
                      className="yandex_map__inner"
                      defaultState={{
                        behaviors: ['disable(\'scrollZoom\')'],
                        center: coordinates,
                        controls: ['zoomControl', 'fullscreenControl'],
                        zoom: 14,
                      }}
                      modules={['control.ZoomControl', 'control.FullscreenControl']}
                      style={{ width: '100%', height: `${matches.md ? '550px' : '350px'}` }}
                    >
                      <Placemark
                        defaultGeometry={coordinates}
                        modules={['geoObject.addon.balloon']}
                        properties={{
                          balloonContent: '',
                          iconCaption: mapPinCaption,
                        }}
                      />
                    </Map>
                  </YMaps>
                )}
              </Media>

            </div>
          );
        }}
      </Scene>
    </Controller>
  );
};

export default YandexMap;
