import raw from 'utils/raw';
import React from 'react';
import typograf from 'utils/typograf';
import { string } from 'prop-types';

const FooterContacts = ({
  adress, tel, note, email,
}) => (
  <div className="footer_contacts">
    <h4 className="title title-icon footer_contacts__title">{typograf('Связаться с нами')}</h4>

    <div className="footer_contacts__body">
      <a href={`+tel:${tel}`} className="link link-black link-undecorated link-bold footer_contacts__tel">{tel}</a>
      <p className="footer_contacts__note">{raw(typograf(note))}</p>
      <a href={`mailto:${email}`} className="link link-bold footer_contacts_email">{email}</a>
      <p><strong className="text text-bold footer_contacts__adress">{adress}</strong></p>
    </div>
  </div>
);

FooterContacts.propTypes = {
  adress: string.isRequired,
  email: string.isRequired,
  note: string.isRequired,
  tel: string.isRequired,
};

export default FooterContacts;
