import Helmet from 'react-helmet';
import React from 'react';
import { string, arrayOf, object } from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import logo from 'images/logo.png';

const SEO = ({
  description, lang, meta, title, keyWords,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
            description
            keyWords
            seoTitle
            sharingImage
            siteTitle
            siteUrl
          }
        }
      }
    `,
  );

  // Contants
  const metaDescription = description || site.siteMetadata.description;
  const metaKeyWords = keyWords || site.siteMetadata.keyWords;
  const metaSeoTitle = title || site.siteMetadata.seoTitle;
  const metaSiteTitle = site.siteMetadata.siteTitle;
  const { sharingImage, siteUrl } = site.siteMetadata;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaSeoTitle}
      titleTemplate={`${metaSiteTitle} | %s`}
      meta={[
        {
          name: 'author',
          content: site.siteMetadata.author,
        },
        {
          name: 'keywords',
          content: metaKeyWords,
        },
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: metaSiteTitle,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          content: `${siteUrl}/${sharingImage}`,
        },
        {
          name: 'theme-color',
          content: '#38C9F2',
        },
        {
          name: 'yandex-verification',
          content: 'cb014c08754db402',
        },
      ].concat(meta)}
    />
  );
};

SEO.propTypes = {
  description: string,
  keyWords: string,
  lang: string,
  meta: arrayOf(object),
  title: string,
};

SEO.defaultProps = {
  description: null,
  keyWords: null,
  lang: 'ru',
  meta: [],
  title: null,
};

export default SEO;
