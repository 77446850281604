import React from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import { node, bool } from 'prop-types';
import { Tween } from 'react-gsap';

const ParallaxElement = ({ children, scale }) => (
  <Controller>
    <Scene
      duration={350}
      triggerHook="onEnter"
    >
      <Tween
        from={{
          opacity: 0,
          scale: scale ? 0.4 : 1,
          y: 250,
        }}
      >
        <div>
          {children}
        </div>
      </Tween>
    </Scene>
  </Controller>
);

ParallaxElement.propTypes = {
  children: node.isRequired,
  scale: bool,
};

ParallaxElement.defaultProps = {
  scale: false,
};

export default ParallaxElement;
