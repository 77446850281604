import React from 'react';
import { string } from 'prop-types';
import typograf from 'utils/typograf';

const Copyright = ({ copyright, client }) => (
  <div className="copyright">
    <div className="copyright__client"><span className="text text-semi">{typograf(client)}</span></div>
    <div className="copyright__developer">
      <a href="http://propellers.team" title="Developed by Propellers since 2019" className="link link-black link-undecorated propeller">{typograf(copyright)}</a>
    </div>
    <div style={{ marginTop: 15 }}><span className="text text-hint text-semi">Раньше наша компания называлась Эксклюзив</span></div>
  </div>
);

Copyright.propTypes = {
  client: string.isRequired,
  copyright: string.isRequired,
};

export default Copyright;
