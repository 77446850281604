import cn from 'classnames';
import React, { Fragment } from 'react';
import { bool } from 'prop-types';

import siteMap from 'data/site_map.yaml';

import NavLinks from './nav_links';

const Nav = ({ isOpen, footer }) => {
  const titleCN = cn('nav__title', { 'nav__title-light': footer });

  return (
    <dl className="nav">
      {siteMap.map(({ category, pages }) => (
        <Fragment key={category}>
          <dt className={titleCN}>{category}</dt>
          <dd className="nav__links">
            <NavLinks links={pages} isOpen={isOpen} footer />
          </dd>
        </Fragment>
      ))}
    </dl>
  );
};

Nav.propTypes = {
  footer: bool,
  isOpen: bool,
};

Nav.defaultProps = {
  footer: false,
  isOpen: false,
};

export default Nav;
