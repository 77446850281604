import AniLink from 'gatsby-plugin-transition-link/AniLink';
import cn from 'classnames';
import React from 'react';
import { bool } from 'prop-types';
import { Tween } from 'react-gsap';

import logo from 'images/logo.png';

import settings from 'utils/settings';

const HeaderLogo = ({ isSticky }) => {
  const imageClassName = cn('header_logo__image', { 'header_logo__image-small': isSticky });

  return (
    <AniLink
      activeClassName="header_logo-disabled"
      bg={settings.brandGradient}
      className="header_logo"
      cover
      direction="right"
      duration={1}
      to="/"
    >
      <Tween from={{ y: '-20px', opacity: 0 }} delay="0.5">
        <img src={logo} className={imageClassName} alt="UVAS" />
      </Tween>
    </AniLink>
  );
};

HeaderLogo.propTypes = {
  isSticky: bool.isRequired,
};

export default HeaderLogo;
