import cn from 'classnames';
import React from 'react';
import Sticky from 'react-stickynode';
import { bool, func } from 'prop-types';

import Container from 'components/layout/container';
import Hamburger from 'ui/hamburger';

import HeaderContacts from './header/header_contacts';
import HeaderLogo from './header/header_logo';

const Header = ({ isOpen, onClick }) => (
  <Sticky className="sticky_header">
    {({ status }) => {
      const isSticky = status === 2;

      const headerClassName = cn('header', { 'header-sticky': isSticky });
      const headerInnerClassName = cn('header__inner', { 'header__inner-sticky': isSticky });

      return (
        <header className={headerClassName}>
          <Container>
            <div className={headerInnerClassName}>
              <div className="header__logo"><HeaderLogo isSticky={isSticky} /></div>
              <div className="header__contacts"><HeaderContacts /></div>
              <div className="header__menu">
                <Hamburger isOpen={isOpen} onClick={onClick} />
              </div>
            </div>
          </Container>
        </header>
      );
    }}
  </Sticky>
);


Header.propTypes = {
  isOpen: bool.isRequired,
  onClick: func.isRequired,
};

export default Header;
