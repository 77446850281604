import * as Yup from 'yup';
import axios from 'axios';
import objectToFormData from 'object-to-formdata';
import React from 'react';
import typograf from 'utils/typograf';
import { useMergeState } from 'react-hooks-lib';
import { useStaticQuery, graphql } from 'gatsby';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';

const formId = 'pFwLohl7o4D';
const url = `https://formcarry.com/s/${formId}`;

// Validation
const formSchema = Yup.object().shape({
  contacts: Yup.string().required('Как с вами связаться?'),
});

const RequestForm = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          contacts {
            tel
          }
        }
      }
    }
  `);

  const { tel } = data.site.siteMetadata.contacts;

  // States
  const { set, state } = useMergeState({ status: null });

  // Handles
  const handleServerResponse = (status) => {
    set(() => ({ status }));
  };

  const handleOnSubmit = (values, actions) => {
    const fd = objectToFormData(values);

    axios.post(
      url,
      fd,
      { headers: { Accept: 'application/json' } },
    )
      .then(() => {
        // handle success
        actions.setSubmitting(false);
        actions.resetForm();
        handleServerResponse('SUCCESS');
      })
      .catch(() => {
        // handle error
        actions.setSubmitting(false);
        handleServerResponse('ERROR');
      });
  };

  // View
  if (state.status === 'SUCCESS') {
    return (
      <>
        <h5>{typograf('Сообщение успешно отправлено!')}</h5>
        <p>{typograf('Мы обязательно с вами свяжемся')}</p>
      </>
    );
  }

  if (state.status === 'ERROR') {
    return (
      <>
        <h5>{typograf('Сожалеем, но произошла ошибка!')}</h5>
        <p>
          {typograf('Попробуйте отправить сообщение еще раз, или позвоните нам:')}
          {' '}
          <a href={`tel:${tel}}`} className="link link-undecorated">{tel}</a>
        </p>
      </>
    );
  }

  return (
    // Form
    <Formik
      initialValues={{ message: '', contacts: '' }}
      onSubmit={handleOnSubmit}
      validationSchema={formSchema}
    >
      {({ isSubmitting }) => (
        <Form noValidate className="form">
          <fieldset className="form__row">
            <label className="form__label" htmlFor="message">Сообщение</label>
            <Field
              className="form__input"
              component="textarea"
              id="message"
              name="message"
              type="text"
              rows="4"
            />
            <ErrorMessage
              className="error_message"
              component="p"
              name="message"
            />
          </fieldset>

          <fieldset className="form__row">
            <label
              className="form__label form__label-required"
              htmlFor="contacts"
            >
              Почта или телефон
            </label>
            <Field className="form__input" id="contacts" type="text" name="contacts" />
            <ErrorMessage
              className="error_message"
              component="p"
              name="contacts"
            />
          </fieldset>

          <fieldset className="form__row form__row-footer">
            <button className="button" type="submit" disabled={isSubmitting}>
              {isSubmitting ? '...' : 'Отправить'}
            </button>
          </fieldset>
        </Form>
      )}
    </Formik>
  );
};

export default RequestForm;
